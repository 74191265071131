
import { createColumn, formatAsDate, formatAsCurrency } from '@/util/table';
import PaginationMixin from '@/mixins/PaginationMixin';
import { OrderStatus } from '@/repositories/OrderRepository';
import RequestOrder from '@/models/RequestOrder';
import { handleErrorUI, handleSuccessUI } from '@/util/error';
import CommonMixin from '@/mixins/CommonMixin';
import OrderSummaryModal from '@/components/Tools/Order/OrderSummaryModal.vue';

export default {
  components: { OrderSummaryModal },
  mixins: [CommonMixin, PaginationMixin],
  data() {
    return {
      data: [],
      loading: false,
      appends: [
        createColumn('reviewDate', 'Reviewed Date', formatAsDate),
        createColumn('finalizedDate', 'Finalized Date', formatAsDate),
        createColumn(
          'grandTotal',
          'Request Grand Total',
          formatAsCurrency(row => row.currency.code)
        ),
        createColumn('paymentMethod', 'Payment Method')
      ],
      paymentConfirmationDialogShown: false,
      acceptPaymentDialogShown: false,
      cancelOrderDialogShown: false,
      selectedOrder: new RequestOrder()
    };
  },
  computed: {
    selectedOrderDisplayName() {
      if (this.selectedOrder == null || this.selectedOrder.id == null)
        return '';
      const date = this.selectedOrder.requestDate.toLocaleString();
      return `Order from ${this.selectedOrder.company} (${this.selectedOrder.store}) on ${date}`;
    }
  },
  async created() {
    await this.fetchOrders();
  },
  methods: {
    showPaymentConfirmationDialog(order: RequestOrder) {
      this.selectedOrder = order;
      this.paymentConfirmationDialogShown = true;
    },
    showAcceptPaymentDialog(order: RequestOrder) {
      this.selectedOrder = order;
      this.acceptPaymentDialogShown = true;
    },
    showCancelOrderDialog(order: RequestOrder) {
      this.selectedOrder = order;
      this.cancelOrderDialogShown = true;
    },
    async fetchOrders() {
      this.loading = true;
      try {
        const { tenant } = await this.$repo.session.fetch();
        const { data, pagination } = await this.$repo.order.getOrderWithStatus(
          tenant,
          OrderStatus.WAITING_FOR_PAYMENT,
          this.pagination
        );
        this.data = data;
        this.updatePagination(pagination);
      } catch (err) {
        handleErrorUI(err);
      } finally {
        this.loading = false;
      }
    },
    async paginationChangeHandler() {
      await this.fetchOrders();
    },
    async onPaymentMethodConfirmed() {
      this.paymentConfirmationDialogShown = false;
      await this.fetchOrders();
    },
    async onCancelOrder() {
      try {
        await this.$repo.order.cancelOrder(this.selectedOrder);
        handleSuccessUI(
          `${this.selectedOrderDisplayName} has been successfully cancelled`
        );
      } catch (err) {
        handleErrorUI(err);
      }
    },
    onOrderCancelled() {
      this.removeSelectedOrder();
    },
    async onAcceptPayment() {
      try {
        await this.$repo.order.acceptPayment(this.selectedOrder);
        handleSuccessUI(
          `Payment for ${this.selectedOrderDisplayName} has been accepted`
        );
      } catch (err) {
        handleErrorUI(err);
      }
      this.acceptPaymentDialogShown = false;
      this.removeSelectedOrder();
    },
    removeSelectedOrder() {
      this.data = this.data.filter(order => order.id !== this.selectedOrder.id);
    }
  }
};
